import React from 'react'
import ErrorPage from 'next/error'
import { captureException, withScope, Severity } from '@sentry/nextjs'

export default class MyError extends React.Component<{ statusCode: number }> {
  static getInitialProps({ res, err }) {
    const statusCode = res
      ? res.statusCode
      : err
      ? (err as any).statusCode
      : null
    if (statusCode !== 200) {
      console.log({ statusCode, err })
      if (statusCode === 404) {
        // withScope((scope) => {
        //   scope.setLevel(Severity.Warning)
        //   captureException(new Error(err?.message ?? statusCode))
        // })
      } else captureException(new Error(err?.message ?? statusCode))
    }
    return { statusCode, error: err }
  }
  render() {
    return <ErrorPage statusCode={this.props.statusCode} />
  }
}
